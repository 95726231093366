import '@rainbow-me/rainbowkit/styles.css';
import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import { http } from 'viem';
import { createConfig, type CreateConfigParameters , cookieStorage, createStorage } from 'wagmi';


import config from 'configs/app';
import currentChain from 'lib/web3/currentChain';
const feature = config.features.blockchainInteraction;

const wagmiConfig = (() => {
  const chains: CreateConfigParameters['chains'] = [ currentChain ];

  if (!feature.isEnabled) {
    const wagmiConfig = createConfig({
      chains: [ currentChain ],
      transports: {
        [currentChain.id]: http(config.chain.rpcUrl || `${ config.api.endpoint }/api/eth-rpc`),
      },
      ssr: true,
      
      batch: { multicall: { wait: 100 } },
      storage: createStorage({
        storage: cookieStorage
      })
    });

    return wagmiConfig;
  }

  const wagmiConfig = getDefaultConfig({
    appName: 'My RainbowKit App',
    chains,
    projectId: feature.walletConnect.projectId
  });

  return wagmiConfig;
})();

export default wagmiConfig;
